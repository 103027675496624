<template>
  <div :class="isMobile ? 'm-corotong' : 'corotong'">
    <div :class="isMobile ? 'm-banner':'banner'">
      <img src="../../assets/image/corotong/banner.png" alt="">
      <div class="white" :class="isMobile ? 'm-talents-text':'talents-text'">
        <div :class="isMobile ? 'm-box-all':'box-all'">
          <div :class="isMobile ? 'm-box-foot':'box-foot'"></div>
        </div>
        <div :class="isMobile ? 'm-title':'title'">科若通</div>
        <div :class="isMobile ? 'm-description':'description'">合法报关、合规外汇、合理退税</div>
      </div>
    </div>
    <div :class="isMobile ? 'm-synthesis':'synthesis'">
      <div :class="isMobile ? 'm-synthesis-content':'synthesis-content'">
        <div  class="font-family-AlimamaShuHeiTi title" :class="isMobile ? 'm-synthesis-title':'synthesis-title'">
          <p class="text-align-center">外贸综合服务</p>
        </div>
        <div v-if="!isMobile" class="content">
          <div class="gird">
            <div class="gird-img"><img src="../../assets/image/corotong/wm-one.png"/></div>
            <div class="gird-text">
              <p>"9610" 全称 "跨境贸易电子商务"，适用于境内个人或电子商</p>
              <p> 务企业通过电子商务交易平台实现交易，并采用 "清单核放、</p>
              <p> 汇总申报" 模式办理通关手续的电子商务零售进出口商品。</p>
            </div>
          </div>
          <div class="gird">
            <div class="gird-img"><img src="../../assets/image/corotong/wm-two.png"/></div>
            <div class="gird-text">
              <p>"1210" 全称 "跨境电商特殊区域出口"。其主要包括两种模</p>
              <p> 务企业通过电子商务交易平台实现交易，并采用 "清单核放、</p>
              <p> 汇总申报" 模式办理通关手续的电子商务零售进出口商品。</p>
            </div>
          </div>
        </div>
        <div v-else class="m-content">
          <div class="m-gird">
            <img src="../../assets/image/corotong/wm-one.png"/>
            <div class="m-gird-text">
              <p>"9610"全称"跨境贸易电子商务"，适用于境内个人或电子商</p>
              <p> 务企业通过电子商务交易平台实现交易，并采用"清单核放、</p>
              <p> 汇总申报"模式办理通关手续的电子商务零售进出口商品。</p>
            </div>
          </div>
          <div class="m-gird" style="margin-top: .5rem">
            <img src="../../assets/image/corotong/wm-one.png"/>
            <div class="m-gird-text">
              <p>"1210"全称"跨境电商特殊区域出口"。其主要包括两种模</p>
              <p> 务企业通过电子商务交易平台实现交易，并采用"清单核放、</p>
              <p> 汇总申报"模式办理通关手续的电子商务零售进出口商品。</p>
            </div>
          </div>
        </div>
        <div class="text-align-center" :class="isMobile ? 'm-xz-btn':'btn'" @click="goto"><el-button>使用服务<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
    </div>
    <div :class="isMobile? 'm-declare':'declare'" class="text-align-center title">
      <div class="font-family-AlimamaShuHeiTi" :class="isMobile ? 'm-declare-title':'padding-t-100 declare-title'">申报流程</div>
      <div v-if="!isMobile" class="margin-t-40 declare-img"><img src="../../assets/image/corotong/declare.png" alt="" /></div>
      <div v-else class="m-declare-content"><img src="../../assets/image/corotong/mobile.png" alt=""></div>
    </div>
    <div :class="isMobile? 'm-other-foreign':'other-foreign'">
      <div class="other-foreign-content text-align-center">
        <div class="font-family-AlimamaShuHeiTi" :class="isMobile?'m-other-foreign-title':'other-foreign-title'">其他外贸综合服务</div>
        <ul v-if="!isMobile" class="other-content">
          <li v-for="(item, index) in list" :key="index">
            <div class="img-text">
              <img :src="item.icon" alt="" />
              <p class="margin-t-20">{{ item.title }}</p>
            </div>
            <div class="other-box">
              <div class="img-text-box">
                <img :src="item.activeIcon" alt="" />
                <p class="margin-t-20">{{ item.title }}</p>
              </div>
              <div class="desc">
                <div class="margin-t-20">
                  <p>{{ item.text1 }}</p>
                  <p>{{ item.text2 }}</p>
                  <p>{{ item.text3 }}</p>
                  <p>{{ item.text4 }}</p>
                  <p>{{ item.text5 }}</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else class="m-other-content">
          <li v-for="(item, index) in list" :key="index">
            <div class="icon"><img :src="index == 1 || index == 2 || index == 5 || index == 6? item.activeIcon:item.icon" alt="" /></div>
            <div class="title">{{ item.title }}</div>
            <div class="divider" :style="index == 1 || index == 2 || index == 5 || index == 6? 'border-bottom: 1px solid rgba(255,255,255,.5)':'border-bottom: 1px solid rgba(16, 40, 103, .5);'"></div>
            <p>{{ item.text1 }}</p>
            <p>{{ item.text2 }}</p>
            <p>{{ item.text3 }}</p>
            <p>{{ item.text4 }}</p>
            <p>{{ item.text5 }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import { isMobileDevice } from '../../utils/detectDevice';
import other1 from '../../assets/image/corotong/other-1.png'
import otherOne from '../../assets/image/corotong/other-active-1.png'
import other2 from '../../assets/image/corotong/other-2.png'
import otherTwo from '../../assets/image/corotong/other-active-2.png'
import other3 from '../../assets/image/corotong/other-3.png'
import otherThree from '../../assets/image/corotong/other-active-3.png'
import other4 from '../../assets/image/corotong/other-4.png'
import otherFour from '../../assets/image/corotong/other-active-4.png'
import other5 from '../../assets/image/corotong/other-5.png'
import otherFive from '../../assets/image/corotong/other-active-5.png'
import other6 from '../../assets/image/corotong/other-6.png'
import otherSix from '../../assets/image/corotong/other-active-6.png'
import other7 from '../../assets/image/corotong/other-7.png'
import otherSeven from '../../assets/image/corotong/other-active-7.png'
import other8 from '../../assets/image/corotong/other-8.png'
import otherEight from '../../assets/image/corotong/other-active-8.png'
export default {
  name: "corotong",
  data() {
    return {
      isMobile: isMobileDevice(),
      other1,
      otherOne,
      other2,
      otherTwo,
      other3,
      otherThree,
      other4,
      otherFour,
      other5,
      otherFive,
      other6,
      otherSix,
      other7,
      otherSeven,
      other8,
      otherEight,
      list: [
        {id: 1, icon: other1, activeIcon: otherOne, title: '财税合规', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 2, icon: other2, activeIcon: otherTwo, title: '金融服务', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 3, icon: other3, activeIcon: otherThree, title: '人才服务', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 4, icon: other4, activeIcon: otherFour, title: '工商注册', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 5, icon: other5, activeIcon: otherFive, title: '订阅报关单', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 6, icon: other6, activeIcon: otherSix, title: '海外市场咨询', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 7, icon: other7, activeIcon: otherSeven, title: '跨境转运服务', text1:'税务规划和优化', text2:'跨国税务合规性咨询', text3:'进出口税收咨询', text4:'企业税收风险管理', text5:'税务申报和退税处理'},
        {id: 8, icon: other8, activeIcon: otherEight, title: '外贸工具', text1:'国际贸易协议和标准', text2:'汇率计算器和转换工具', text3:'货物分类和关税计算', text4:'出口入口合规性检查', text5:'贸易信息和新闻订阅'},
      ]
    }
  },
  methods: {
    goto() {
      let routeUrl =  this.$router.resolve({ path: './coming-soon'})
      window.open(routeUrl.href, '_blank');
    },
  }
}
</script>

<style scoped lang="less">
.corotong {
  width: 100%;
  height: 100%;
  color: #11142C;
  .banner {
    width: 100%;
    height: 960px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .talents-text {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      width: 1280px;
      margin: 0 auto;
      color: white;
      .title {
        font: 80px AlimamaShuHeiTi;
        font-weight: 700;
      }
      .description {
        width: 960px;
        font-size: 30px;
        font-weight: 400;
        margin: 60px auto 0;
      }
      .box-all{
        width: 180px;
        height: 160px;
        border-top: 8px solid #ffffff;
        border-bottom: 8px solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -15%;
        left: 50%;
        transform: translateX(-50%);
      }
      .box-all:before, .box-all:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        top: 0;
      }
      .box-all:before, .box-foot:before{
        border-left: 8px solid #ffffff;
        left: 0px;
      }
      .box-all:after,.box-foot:after{
        border-right: 8px solid #ffffff;
        right: 0;
      }
      .box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .box-foot:before, .box-foot:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        bottom: 0;
      }
    }
  }
  .synthesis {
    width: 100%;
    height: 960px;
    .synthesis-content {
      width: 1280px;
      height: 960px;
      margin: 0 auto;
      .title {
        color: #121212;
      }
      .synthesis-title {
        font-size: 60px;
        margin : 100px auto;
      }
      .content {
        width: 1280px;
        height: 480px;
        margin-bottom: 100px;
        .gird {
          width: 600px;
          height: 480px;
          background-color: #F8F8F8;
          border-radius: 4px;
          float: left;
          .gird-img {
            width: 100%;
            height: 300px;
            img {
              background-size: cover;
            }
          }
          .gird-text {
            padding: 35px 0 20px 35px;
            font-size: 20px;
            color: #121212;
          }
        }
        .gird:nth-child(1) {
          margin-right: 80px;
        }
      }
      .btn .el-button--small {
        padding: 13px 28px;
        color: #ffffff;
        font-size: 20px;
      }
      .btn .el-button {
        width: 168px;
        height: 48px;
        background-color: #102867;
        border-color: #102867;
      }
    }
  }
  .declare {
    width: 100%;
    height: 960px;
    background: url("../../assets/image/corotong/declare-bg.png");
    background-size: 100%;
    .declare-title {
      font-size: 60px;
    }
    .declare-img {
      width: 1240px;
      height: 590px;
      margin: 100px auto 0;
    }
  }
  .other-foreign {
    width: 100%;
    height: 960px;
    .other-foreign-content {
      width: 1280px;
      height: 960px;
      margin: 0 auto;
    }
    .other-foreign-title {
      padding-top: 100px;
      margin-bottom: 60px;
    }
    .other-content {
      width: 1280px;
      height: 547px;
      li {
        width: 270px;
        height: 240px;
        float: left;
        margin-right: 66.67px;
        margin-bottom: 66.67px;
        border-radius: 4px;
        background-color: #f8f8f8;
        position: relative;
        display: flex;
        .img-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          img {
            width: 60px;
            height: 60px;
          }
          p {
            width: 270px;
            height: 30px;
            color: #121212;
            font-size: 20px;
          }
        }
        .other-box {
          width: 270px;
          height: 520px;
          background-color: #102867;
          color: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 0px 24px 0px rgba(16, 40, 103, 0.3);
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 99;
          .img-text-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 270px;
            height: 240px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            img {
              width: 60px;
              height: 60px;
            }
            p {
              width: 270px;
              height: 30px;
              color: #ffffff;
              font-size: 20px;
            }
          }
          .desc {
            width: 180px;
            height: 280px;
            font-size: 18px;
            color: #ABB3C6;
            line-height: 48px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            border-top: 1px solid #ffffff;
          }
        }

      }
      li:hover .other-box {
        display: block;
      }
      li:last-child, li:nth-child(4) {
        margin-right: 0;
      }
    }
  }
}
/*移动端*/
.m-corotong {
  .m-banner {
    width: 100%;
    height: 5rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .m-talents-text {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      margin: 0 .8rem;
      .m-title {
        font-size: .5rem;
        font-family: AlimamaShuHeiTi;
      }
      .m-description {
        font-size: .24rem;
        margin: .5rem auto 0;
        width: 90%;
      }
      .m-box-all{
        width: 1.25rem;
        height: 1rem;
        border-top: .05rem solid #ffffff;
        border-bottom: .05rem solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -7.5%;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-box-all:before, .m-box-all:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        top: 0;
      }
      .m-box-all:before, .m-box-foot:before{
        border-left: .05rem solid #ffffff;
        left: 0px;
      }
      .m-box-all:after,.m-box-foot:after{
        border-right: .05rem solid #ffffff;
        right: 0;
      }
      .m-box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .m-box-foot:before, .m-box-foot:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        bottom: 0;
      }
    }
  }
  .m-synthesis {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    .m-synthesis-content {
      width: 100vw;
      height: 15.5rem;
      .m-synthesis-title {
        font-size: .55rem;
        color: #121212;
        margin: .5rem auto;
      }
      .m-content {
        width: 100vw;
        height: 12.5rem;
        margin-bottom: .5rem;
      }
      .m-gird {
        width: 8rem;
        height: 6rem;
        background-color: #F8F8F8;
        border-radius: .04rem;
        margin: 0 auto;
        position: relative;
        .m-gird-text {
          width: 7.43rem;
          height: 1.6rem;
          font-size: .28rem;
          color: #121212;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .m-xz-btn .el-button--small {
        margin: 0 auto;
        padding: .13rem .28rem;
        color: #ffffff;
        font-size: 0.2rem;
        background-color: #102867;
        display: block;
      }
    }

  }
  .m-declare {
    width: 100vw;
    height: 16.5rem;
    background: url("../../assets/image/corotong/declare-bg.png") no-repeat;
    background-size: auto 100%;
    overflow: hidden;
    .m-declare-title {
      font-size: .55rem;
      margin: .5rem auto;
      color: #121212;
    }
    .m-declare-content {
      width: 90%;
      height: 14rem;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .m-other-foreign {
    width: 100vw;
    height: 30.5rem;
    overflow: hidden;
    .m-other-foreign-title {
      font: .55rem AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: .5rem auto;
    }
    .m-other-content {
      width: 95%;
      height: 28.02rem;
      background-color: #102867;
      margin: 0 auto;
      border-radius: .04rem;
      border: .01rem solid rgba(16, 40, 103, .8);
      background-color: #f8f8f8;
      li {
        color: #121212;
        width: 50%;
        height: 7rem;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .icon {
          width: 1.2rem;
          height: 1.2rem;
          margin: 0 auto .2rem;
          img {
            width: 100%;
            height: auto;
          }
        }
        .title {
          font-size: .35rem;
        }
        p {
          font-size: .3rem;
          margin-bottom: .2rem;
        }
        .divider {
          width: 70%;
          margin: .3rem 0;
        }
      }
      li:nth-child(2), li:nth-child(3), li:nth-child(6), li:nth-child(7)  {
        background-color: rgba(16, 40, 103, 100);
        color: white;
        p {
          color: #eee;
        }
      }
    }
  }
}
</style>
